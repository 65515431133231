// /Users/jamelbraun/Google\ Drive/Business\ /Dev\ Projects/crunchforms/wedding

import React, { Component } from 'react';
import background from './Jamel&Teresa-238.jpg';
import './App.css';

const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

document.title = "Jamel & Teresa"

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBnq3JvDLlwJRcLLBMSfheQyih6LPQdh18",
    authDomain: "hooknote-29905.firebaseapp.com",
    databaseURL: "https://hooknote-29905.firebaseio.com",
    projectId: "hooknote-29905",
    storageBucket: "hooknote-29905.appspot.com",
    messagingSenderId: "810617771979",
    appId: "1:810617771979:web:4b9dd2d56dc97ad6"
};
firebase.initializeApp(config);
const db = firebase.firestore();


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: '',
      name: '',
      lastName: '',
      responded: false,
      response: false,
      group: [],
      loaded: false
    };
  };
  componentWillMount() {
    //WARNING!! this resets database when run
    //setGuestlist()

    const slug = getSlug();
    if(slug.length >0){
      //console.log(slug)
      this.setState({
        slug: slug
      });

      db.collection('RSVP').doc(slug).get()
    .then(docSnap => {
      if(docSnap.exists){
        //console.log('exists')
        //console.log(docSnap.get('group'))
        this.setState({
          name: docSnap.get('firstName'),
          lastName: docSnap.get('lastName'),
          responded: docSnap.get('responded'),
          response: docSnap.get('response'),
          group: docSnap.get('group'),
          loaded: true

        });
      }
    })
    }
  }
  render() {
    return (
      <div className="App">
        <div className="backImgContainer">
          <img className="backImg" src={background} alt="" />
        </div>
        <div className="galleryButton">
          {/* <i className="material-icons-round md-48">photo_camera</i> */}
        </div>
        <div className="splash">
          <Card id={this.state.slug} name={this.state.name} responded={this.state.responded} response={this.state.response} group={this.state.group} loaded={this.state.loaded}/>
        </div>
        
      </div>
    );
  }
}

class Card extends Component {
  constructor(props) {
    super(props);
    this.mapClicked = this.mapClicked.bind(this);
    this.state = {
      showMap: false
    };
  };
  mapClicked () {
    this.setState({
      showMap: !this.state.showMap
    });
  }
  render() {
    //console.log(this.props.loaded +  ' ')
    let name = '';
    if(this.props.name.length > 0){
      name = ' ' + this.props.name;
    }
    const date = new Date(2020, 9, 10, 16, 0, 0, 0);
    const dateString = stringFromTodayToDate(date);
    return (
      <div className="card">
        <h1>Teresa &#038; Jamel</h1>
        <h2>Hello{name}, we would be honoured if you would join us</h2>
        <br />
        <p>At 4 o'clock in the afternoon of</p>
        <h2>October | 10 | 2020</h2>
        <hr />
        <p>In {dateString}</p>
        <br />
        <p>Ceremony and Reception at <span className="pButton" onClick={this.mapClicked}>Pembina Pines<i className="material-icons-two-tone md-24">map</i></span></p>
        {this.state.showMap ? <Map /> : <button onClick={this.mapClicked}>Map</button>}
        <br />
        <br />
        <hr />
        <br />
        <h2>Please <a href="mailto:hello@teresaandjamel.com?subject=RSVP to Teresa and Jamel's Wedding"><span className="pButton">RSVP</span></a> by September 1, 2020</h2><p></p>
        
        {!this.props.loaded ? <RSVPSearch /> : null}
        {this.props.loaded ? <RSVP id={this.props.id} name={this.props.name} responded={this.props.responded} response={this.props.response} group={this.props.group} loaded={this.props.loaded} /> : null }
        <br />
        <br />
        <br />
        <a href="https://youtu.be/dQw4w9WgXcQ?t=43"> <i className="material-icons-two-tone md-36 heartbeat">favorite</i></a>
      </div>
    );
  }
}

class RSVP extends Component {
  constructor(props) {
    super(props);
    this.radioClick = this.radioClick.bind(this);
    this.groupMemberClick = this.groupMemberClick.bind(this);
    this.saveRsvp = this.saveRsvp.bind(this);
    this.state = {
      response: true,
      groupSnaps: [],
      rsvpLoaded: false,
      rsvpGroup:[],
      saveText: 'Send RSVP',
      head: 'RSVP',
      thankYou: false
    };
  };
  componentWillMount(){
    this.checkLoad()
  }
  checkLoad(){
    if(this.props.loaded && !this.state.rsvpLoaded){
      if(this.props.responded){
        this.setState({
          response: this.props.response,
          saveText:'Update RSVP',
          head: 'Update RSVP'
        });
      }

      this.setState({rsvpLoaded: true
      });

      //get group names
      //console.log(this.props.group)
      let groupNamesPromise = [];
      let defaultRsvpGroup = [this.props.id];
      if(this.props.group && this.props.group.length > 0){
        this.props.group.forEach(function (name, index) {
          defaultRsvpGroup.push(name)
          groupNamesPromise.push(db.collection('RSVP').doc(name).get())
        });
      }
      
      this.setState({
        rsvpGroup: defaultRsvpGroup
      });

      Promise.all(groupNamesPromise)
      .then(groupSnaps => {
        //console.log(groupSnaps)
        this.setState({
          groupSnaps: groupSnaps
        });
      })
    }
  }
  radioClick(response) {
    this.setState({
      response: response
    });
  }
  groupMemberClick(nameID){
    let temp = [];

    if(this.state.rsvpGroup.includes(nameID)){
      this.state.rsvpGroup.forEach(function (name, index) {
        if(name !== nameID){
          temp.push(name)
        }
      })
    }else{
      temp = this.state.rsvpGroup
      temp.push(nameID)
    }

    this.setState({
      rsvpGroup: temp
    });
  }
  saveRsvp(){
    let updates = [];
    let resp = this.state.response
    this.state.rsvpGroup.forEach((nameID, index) =>{
      updates.push(
        db.collection('RSVP').doc(nameID).update({
          responded: true,
          response: resp
        })
      )
    })
    Promise.all(updates)
    .then(() => {
      console.log("Document successfully updated!");
      this.setState({
        responded: true,
        saveText:'Update RSVP',
        thankYou: true
      });
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
      this.setState({
        saveText: 'Oops, please click to try again'
      });
    });
  }
  edit(){
    this.setState({
      thankYou: false
    });
  }
  render() {
    return (
      <div className="RSVP">
        {!this.state.thankYou ? <div>
        <h2>{this.state.head}:</h2>
        <form>
          <label className="container">
            <input onClick={() => this.radioClick(true)} type="radio" checked={this.state.response} readOnly />
            <p>I will attend.</p>
            <span className="checkmark"></span>
          </label>
          <label className="container">
            <input onClick={() => this.radioClick(false)} type="radio" checked={!this.state.response} readOnly />
            <p>I will not attend.</p>
            <span className="checkmark"></span>
          </label>
        </form>
        {this.state.groupSnaps && this.state.groupSnaps.length>0 ? <h2>along with:</h2> : null}
        {this.state.groupSnaps.map(item => 
          <label className="container" key={item.id}>
            <input onClick={() => this.groupMemberClick(item.id)} type="radio" checked={this.state.rsvpGroup.includes(item.id)} readOnly/>
            <p>{item.get('firstName') + ' ' + item.get('lastName')}</p>
            <span className="checkmarkBox"></span>
          </label>
        )}

        <p onClick={() => this.saveRsvp()} className="pButton centerText">{this.state.saveText}<i className="material-icons-two-tone md-24">arrow_forward_ios</i></p>

        </div>:<div>
        <h1 className="centerText fadeIn">Thank you!</h1>
        <p onClick={() => this.edit()} className="pButton centerText fadeIn">Edit RSVP<i className="material-icons-two-tone md-24">arrow_forward_ios</i></p>
        </div>}
      </div>
    );
  }
}

class RSVPSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputBox: '',
      inputEnabled: true,
      message: 'To RSVP, Please enter your full name:',
      valid: false,
      first: '',
      last: ''
    };
  };
  updateInput(evt) {
    this.setState({
      inputBox: evt.target.value
    });
    let string = evt.target.value
    let words = string.trim().split(' ')

    if(words.length >=2){
      this.setState({
        valid: true,
        first: words[0],
        last: words[1]
      });
    }else{
      this.setState({
        valid: false
      });
    }
    
  }
  nextPressed() {
    this.setState({
      inputEnabled: false
    });

    //should validate for two words before allowing submit
    let firstname = proper(this.state.first)
    let lastname = proper(this.state.last)
    console.log(firstname + lastname)
    db.collection('RSVP').where('firstName', '==', firstname).where('lastName', '==', lastname).get()
    .then((querySnapshot) => {
      if(querySnapshot.size > 0){
        window.location.replace(window.location.href+querySnapshot.docs[0].id)
      }else{
        this.errorMessage('Sorry, something went wrong.. Please RSVP to hello@teresaandjamel.com')
      }
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      this.errorMessage('Sorry, something went wrong.. Please RSVP to hello@teresaandjamel.com')
    });
  }
  errorMessage(message){
    this.setState({
      inputEnabled: true,
      message: message
    });
  }
  render() {
    return (
      <div className="RSVP">
        <h2>{this.state.message}</h2>
        <input onChange={evt => this.updateInput(evt)} className="inputBox" type="text" placeholder="First and Last" disabled={!this.state.inputEnabled} />
        {this.state.valid ? <p onClick={() => this.nextPressed()} className="pButton centerText">Next<i className="material-icons-two-tone md-24">arrow_forward_ios</i></p> : null}
      </div>
    )
  }
}

class Map extends Component {
  render() {
    return (
      <div>
        <h2>NE 36-2-7W - 11126 Road 36W</h2>
        <iframe title="Pines-Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d97540.86337600085!2d-98.25896036584352!3d49.18190055036975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c2572a5e858215%3A0xbbd4640a9bbd7176!2sPembina%20Pines%20Wedding%20%26%20Event%20Venue!5e0!3m2!1sen!2sca!4v1567565314738!5m2!1sen!2sca" width="100%" height="350" frameborder="0" allowfullscreen=""></iframe>
      </div>
    );
  }
}

// function updateGuest(nameID, response){
//   db.collection('RSVP').doc(nameID).update({
//     responded: true,
//     response: response
//   })
//   .then(function() {
//     console.log("Document successfully updated!");
//   })
//   .catch(function(error) {
//     // The document probably doesn't exist.
//     console.error("Error updating document: ", error);
//   });
// }

function proper(txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

function getSlug () {
  let slug = window.location.pathname;
  slug = slug.replace("/", "");
  slug = slug.toLowerCase();
  return slug;
}

function stringFromTodayToDate(date) {
  //const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  
  const now = new Date();
  const elapsed = Math.floor(((date.getTime() - now.getTime())/1000.0)/60); // in minutes
  const days = Math.floor(elapsed/1440.0);
  const hours = Math.floor((elapsed - (days*1440))/60);
  const minutes = Math.floor(elapsed - (days*1440) - (hours*60));

  let string = '';
  if(minutes > 0){
    string = minutes + ' minutes';
  }
  if(hours > 0){
    string = hours + ' hours, ' + string;
  }
  if(days > 0){
    string = days + ' days, ' + string;
  }

  return string;

}

function setGuestlist(){
  const guestlist = {"guests":[
    {
      "id": "sharon",
      "firstName": "Sharon",
      "lastName": "Fehr",
      "group": [
        "myron"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "myron",
      "firstName": "Myron",
      "lastName": "Fehr",
      "group": [
        "sharon"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "lisa",
      "firstName": "Lisa",
      "lastName": "Carson",
      "group": [
        "kellycarson",
        "jackson",
        "ariella"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "kellycarson",
      "firstName": "Kelly",
      "lastName": "Carson",
      "group": [
        "lisa",
        "jackson",
        "ariella"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jackson",
      "firstName": "Jackson",
      "lastName": "Carson",
      "group": [
        "lisa",
        "kellycarson",
        "ariella"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "ariella",
      "firstName": "Ariella",
      "lastName": "Carson",
      "group": [
        "lisa",
        "kellycarson",
        "jackson"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "kim",
      "firstName": "Kim",
      "lastName": "Friesen",
      "group": [
        "kelvin",
        "baby"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "kelvin",
      "firstName": "Kelvin",
      "lastName": "Friesen",
      "group": [
        "kim",
        "baby"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "baby",
      "firstName": "Baby",
      "lastName": "Friesen",
      "group": [
        "kim",
        "kelvin"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "brianfehr",
      "firstName": "Brian",
      "lastName": "Fehr",
      "group": [
        "corinna"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "corinna",
      "firstName": "Corinna",
      "lastName": "Fehr",
      "group": [
        "brianfehr"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "rick",
      "firstName": "Rick",
      "lastName": "Fehr",
      "group": [
        "lois"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "lois",
      "firstName": "Lois",
      "lastName": "Fehr",
      "group": [
        "rick"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jordanfehr",
      "firstName": "Jordan",
      "lastName": "Fehr",
      "group": [
        "nelly"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "nelly",
      "firstName": "Nelly",
      "lastName": "Fehr",
      "group": [
        "jordanfehr"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jared",
      "firstName": "Jared",
      "lastName": "Fehr",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "brett",
      "firstName": "Brett",
      "lastName": "Fehr",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "laura",
      "firstName": "Laura",
      "lastName": "Fehr",
      "group": [
        "ikefehr",
        "logan",
        "octavia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "ikefehr",
      "firstName": "Ike",
      "lastName": "Fehr",
      "group": [
        "laura",
        "logan",
        "octavia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "logan",
      "firstName": "Logan",
      "lastName": "Fehr",
      "group": [
        "laura",
        "ikefehr",
        "octavia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "octavia",
      "firstName": "Octavia",
      "lastName": "Fehr",
      "group": [
        "laura",
        "ikefehr",
        "logan"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jenn",
      "firstName": "Jenn",
      "lastName": "Fehr",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "russ",
      "firstName": "Russ",
      "lastName": "Rosen",
      "group": [
        "doreen"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "doreen",
      "firstName": "Doreen",
      "lastName": "Rosen",
      "group": [
        "russ"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "adele",
      "firstName": "Adele",
      "lastName": "Rosen",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "houda",
      "firstName": "Houda",
      "lastName": "Braun",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "victor",
      "firstName": "Victor",
      "lastName": "Braun",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "nayla",
      "firstName": "Nayla",
      "lastName": "Barnstead",
      "group": [
        "will"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "will",
      "firstName": "Will",
      "lastName": "Barnstead",
      "group": [
        "nayla"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "atieh",
      "firstName": "Atieh",
      "lastName": "Milne",
      "group": [
        "niall"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "niall",
      "firstName": "Niall",
      "lastName": "Milne",
      "group": [
        "atieh"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "gus",
      "firstName": "Gus",
      "lastName": "Mardli",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "andre",
      "firstName": "Andre",
      "lastName": "Mardli",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "abdalla",
      "firstName": "Abdalla",
      "lastName": "Mardli",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "attieh",
      "firstName": "Attieh",
      "lastName": "Mardli",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "grandma",
      "firstName": "Grandma",
      "lastName": "Braun",
      "group": [
        "arnold",
        "marlene",
        "grandpa"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "grandpa",
      "firstName": "Grandpa",
      "lastName": "Braun",
      "group": [
        "arnold",
        "marlene",
        "grandma"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "marlene",
      "firstName": "Marlene",
      "lastName": "Goertzen",
      "group": [
        "arnold",
        "grandma",
        "grandpa"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "arnold",
      "firstName": "Arnold",
      "lastName": "Goertzen",
      "group": [
        "marlene",
        "grandma",
        "grandpa"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "timgoertzen",
      "firstName": "Tim",
      "lastName": "Goertzen",
      "group": [
        "emily"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "emily",
      "firstName": "Emily",
      "lastName": "Goertzen",
      "group": [
        "timgoertzen"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "greg",
      "firstName": "Greg",
      "lastName": "Goertzen",
      "group": [
        "katie"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "katie",
      "firstName": "Katie",
      "lastName": "Goertzen",
      "group": [
        "greg"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "bradgoertzen",
      "firstName": "Brad",
      "lastName": "Goertzen",
      "group": [
        "erin"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "erin",
      "firstName": "Erin",
      "lastName": "Goertzen",
      "group": [
        "bradgoertzen"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "brianenns",
      "firstName": "Brian",
      "lastName": "Enns",
      "group": [
        "sylvia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "sylvia",
      "firstName": "Sylvia",
      "lastName": "Enns",
      "group": [
        "brian"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "ramy",
      "firstName": "Ramy",
      "lastName": "Braun",
      "group": [
        "rosa",
        "nathanael",
        "sophia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "rosa",
      "firstName": "Rosa",
      "lastName": "Braun",
      "group": [
        "ramy",
        "nathanael",
        "sophia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "kellybraun",
      "firstName": "Kelly",
      "lastName": "Braun",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "nathanael",
      "firstName": "Nathanael",
      "lastName": "Braun",
      "group": [
        "ramy",
        "rosa",
        "sophia"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "sophia",
      "firstName": "Sophia",
      "lastName": "Braun",
      "group": [
        "ramy",
        "rosa",
        "nathanael"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "matt",
      "firstName": "Matt",
      "lastName": "Ginter",
      "group": [
        "justine"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "justine",
      "firstName": "Justine",
      "lastName": "Ginter",
      "group": [
        "matt"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "paul",
      "firstName": "Paul",
      "lastName": "Kinsman",
      "group": [
        "alesha"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "alesha",
      "firstName": "Alesha",
      "lastName": "Unrau",
      "group": [
        "paul"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "kayla",
      "firstName": "Kayla",
      "lastName": "Nelson",
      "group": [
        "jordankuprowski"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jordankuprowski",
      "firstName": "Jordan",
      "lastName": "Kuprowski",
      "group": [
        "kayla"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "sam",
      "firstName": "Sam",
      "lastName": "Kalinowski",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "braden",
      "firstName": "Braden",
      "lastName": "Kutzner",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "eric",
      "firstName": "Eric",
      "lastName": "Blaich",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "ryan",
      "firstName": "Ryan",
      "lastName": "Friesen",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "carley",
      "firstName": "Carley",
      "lastName": "Duda",
      "group": [
        "steve"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "steve",
      "firstName": "Steve",
      "lastName": "Duda",
      "group": [
        "carley"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "jadynn",
      "firstName": "Jadynn",
      "lastName": "Wolfe",
      "group": [
        "ashley"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "ashley",
      "firstName": "Ashley",
      "lastName": "Wolfe",
      "group": [
        "jadynn"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "amy-jean",
      "firstName": "Amy-Jean",
      "lastName": "MacLean",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "dillon",
      "firstName": "Dillon",
      "lastName": "Rempel",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "shaedyn",
      "firstName": "Shaedyn",
      "lastName": "Klein",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "colleen",
      "firstName": "Colleen",
      "lastName": "Stewart",
      "group": [
        "randy"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "randy",
      "firstName": "Randy",
      "lastName": "Stewart",
      "group": [
        "colleen"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "dana",
      "firstName": "Dana",
      "lastName": "Shantz",
      "group": [
        "timshantz"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "timshantz",
      "firstName": "Tim",
      "lastName": "Shantz",
      "group": [
        "dana"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "zack",
      "firstName": "Zack",
      "lastName": "Wiebe",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "nicole",
      "firstName": "Nicole",
      "lastName": "Enns",
      "group": [
        "ray"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "ray",
      "firstName": "Ray",
      "lastName": "Ridle",
      "group": [
        "nicole"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "lenny",
      "firstName": "Lenny",
      "lastName": "Wieler",
      "group": [
        "denise"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "denise",
      "firstName": "Denise",
      "lastName": "Wieler",
      "group": [
        "lenny"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "garry",
      "firstName": "Garry",
      "lastName": "Hiebert",
      "group": [
        "laurie"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "laurie",
      "firstName": "Laurie",
      "lastName": "Hiebert",
      "group": [
        "garry"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "haley",
      "firstName": "Haley",
      "lastName": "Funk",
      "group": [
        "bradglover"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "bradglover",
      "firstName": "Brad",
      "lastName": "Glover",
      "group": [
        "haley"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "traycee",
      "firstName": "Traycee",
      "lastName": "Peters",
      "group": "",
      "responded": false,
      "response": false
    },
    {
      "id": "ikefriesen",
      "firstName": "Ike",
      "lastName": "Friesen",
      "group": [
        "lawanda"
      ],
      "responded": false,
      "response": false
    },
    {
      "id": "lawanda",
      "firstName": "Lawanda",
      "lastName": "Fiesen",
      "group": [
        "ikefriesen"
      ],
      "responded": false,
      "response": false
    }
  ]}
    if(guestlist && guestlist !== undefined){
        const rsvp = db.collection('RSVP')
        console.log(guestlist)
        const promises = []
        console.log('guests: '+guestlist.guests)
        guestlist.guests.forEach(guest => {
            promises.concat(rsvp.doc(guest.id).set(guest))
        });
        console.log('Promises: '+promises.length)
        Promise.all(promises)
        .then(responses => {
            console.log('Responses: '+responses.length)
        })
        .catch(error => {
            console.error(error)
        })
    }else{
        console.error('failed')
    }
}

export default App;
